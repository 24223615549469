import styled from 'styled-components';
import media from '../../../constants/media';

export const Wrapper = styled.div`
	position: relative;
	margin: 0 auto;

	${media.tablet`
		padding: 0 2rem;
	`};

	${media.desktopSmall`
		padding: 0 20rem;
	`}

	${media.desktopHuge`
		max-width: 1920px;
	`};
`;

export const Picture = styled.picture`

`;
