import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from '../components/grid/grid';
import { IInfoPageItem } from '../interfaces/infoPageItem/infoPageItem';
import InfoPageImage from '../components/infoPage/infoPageImage';
import InfoPageContent from '../components/infoPage/infoPageContent';

interface Props {
	data: {
		prismicInfoPage: IInfoPageItem
	}
}

const About: FC<Props> = ({ data: { prismicInfoPage } }) => {
	return (
		<Container>
			<InfoPageImage infoPage={prismicInfoPage} />
			<InfoPageContent infoPage={prismicInfoPage} />
		</Container>
	);
};

export default About;

export const pageQuery = graphql`
	query InfoPageBySlug($uid: String!) {
		prismicInfoPage(uid: { eq: $uid}) {
			id
			uid
			data {
				title {
					text
				}
				image {
					url
					mobile {
						url
					}
					tablet {
						url
					}
					desktop {
						url
					}
					retina {
						url
					}
				}
				content {
					html
				}
			}
		}
	}
`;
