import styled from 'styled-components';
import media from '../../../constants/media';

export const Wrapper = styled.div`
	margin: 0 auto;
	margin-bottom: 10rem;
	margin-top: 6rem;
	padding: 0 2rem;

	${media.desktopSmall`
		padding: 0 20rem;
		margin-top: 12rem;
		margin-bottom: 12rem;
	`};

	${media.desktopHuge`
		max-width: 1920px;
		margin: 12rem auto;
	`};
`;

export const MainText = styled.div`
	max-width: 76.8rem;
`;
