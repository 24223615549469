import React, { FC } from 'react';
import * as StyledInfoPageImage from './infoPageImage.styles';
import { IInfoPageItem } from '../../../interfaces/infoPageItem/infoPageItem';

interface Props {
	infoPage: IInfoPageItem
}

const InfoPageImage: FC<Props> = ({
	infoPage
}) => {
	return (
		<StyledInfoPageImage.Wrapper>
			<StyledInfoPageImage.Picture>
				{ infoPage.data.image.retina && <source srcSet={infoPage.data.image.retina.url} media="(min-width: 2560px)" /> }
				{ infoPage.data.image.desktop && <source srcSet={infoPage.data.image.desktop.url} media="(min-width: 1440px)" /> }
				{ infoPage.data.image.tablet && <source srcSet={infoPage.data.image.tablet.url} media="(min-width: 768px)" /> }
				{ infoPage.data.image.mobile && <source srcSet={infoPage.data.image.mobile.url} media="(max-width: 767px)" /> }
				<img src={infoPage.data.image.url} alt={infoPage.data.title.text} />
			</StyledInfoPageImage.Picture>
		</StyledInfoPageImage.Wrapper>
	);
};

export default InfoPageImage;
