import React, { FC, useState } from 'react';
import Interweave from 'interweave';
import * as StyledInfoPageContent from './infoPageContent.styles';
import { IInfoPageItem } from '../../../interfaces/infoPageItem/infoPageItem';
import useOnMount from '../../../hooks/useOnMount';

interface Props {
	infoPage: IInfoPageItem
}

const InfoPageContent: FC<Props> = ({ infoPage }) => {
	// Interweave uses the document object which breaks the code on build time
	const [mounted, setMounted] = useState(false);
	useOnMount(() => setMounted(true));

	return (
		<StyledInfoPageContent.Wrapper>
			<StyledInfoPageContent.MainText>
				{
					mounted && (
						<Interweave content={infoPage.data.content.html} />
					)
				}
			</StyledInfoPageContent.MainText>
		</StyledInfoPageContent.Wrapper>
	);
};

export default InfoPageContent;
