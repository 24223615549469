import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { media } from '../../constants/media/media';

const StyledContainer = styled.div`
	max-width: 100%;

	${media.mobileOnly`
		padding 0 3rem;
	`}
	${media.tabletOnly`
		padding: 0 3rem;
	`}

	${media.desktopSmall`
		padding: 0 3rem;
	`}

	${media.desktopLarge`
		padding-left: 21rem;
		padding-right: 14rem;
	`}

	[name="Container"] {
		padding: 0;
	}
`;

export const Container: FC<{}> = props => <StyledContainer {...props} name="Container" />;

type ColumnsProps = {
	gapless?: boolean;
	flexWrap?: string;
	gap?: string;
	props?: any[];
};

export const Columns: FC<ColumnsProps> = ({ gapless, flexWrap, gap, ...props }) => {
	let rowGap = gapless ? '0' : '-1.5rem';
	if (gap) rowGap = `-${gap}`;

	return <Flex {...props} mx={rowGap} flexWrap={flexWrap || 'wrap'} />;
};

export const Row = Columns;

type ColumnProps = {
	mobile?: string | number;
	mobileMedium?: string | number;
	mobileLarge?: string | number;
	tablet?: string | number;
	desktopSmall?: string | number;
	desktopLarge?: string | number;
	retina?: string | number;
	gapless?: string | number;
	flex?: string | number;
	width?: string | number;
	style?: any;
	gap?: string;
	props?: any[];
};

export const Column: FC<ColumnProps> = ({
	mobile,
	mobileMedium,
	mobileLarge,
	tablet,
	desktopSmall,
	desktopLarge,
	retina,
	gapless,
	flex,
	width,
	gap,
	...props
}) => {
	const breakpoints = [
		'100%',
		mobile || '100%',
		mobileMedium || mobile || '100%',
		mobileLarge || mobileMedium || mobile || '100%',
		tablet || mobileLarge || mobileMedium || mobile || 'auto',
		desktopSmall || tablet || mobileLarge || mobileMedium || mobile || 'auto',
		desktopLarge || desktopSmall || tablet || mobileLarge || mobileMedium || mobile || 'auto',
		retina || desktopLarge || desktopSmall || tablet || mobileLarge || mobileMedium || mobile || 'auto',
	];

	let columnGap = gapless ? 0 : '1.5rem';
	if (gap) columnGap = gap;
	const defaultFlex =
		mobile || mobileMedium || mobileLarge || tablet || desktopSmall || desktopLarge || retina ? 'none' : '1 1 auto';

	return (
		<Box
			width={width || breakpoints}
			{...props}
			px={columnGap}
			mb={`calc(${columnGap} * 2)`}
			name="Column"
			flex={flex || defaultFlex}
		/>
	);
};
